<template>
  <div class="title">
    <div class="custom-flex">
      <div class="icon" />
      <div>订单类型统计</div>
    </div>
    <div
      ref="orderTypeStatisticsChart"
      style="height: 300px; width: 100%"
    />
  </div>
</template>

<script>
import { findTenantHomePageOrderStatistics } from '@/api/home'
export default {
  name: 'OrderTypeStatistics',
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        // 提示框
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.marker + this.$lodash.truncate(params.name, { length: 9 }) +
                '&nbsp;&nbsp;' + params.value + '个'
          }
        },
        // co
        // color: ['#3aa1ff', '#36cbcb'],
        // 图例
        legend: {
          orient: 'vertical',
          right: 0,
          top: 'middle',
          icon: 'circle',
          textStyle: {
            fontSize: '15px'
          },
          formatter: (name) => {
            return this.$lodash.truncate(name, { length: 9 })
          },
          tooltip: {
            show: true,
            formatter: (params) => {
              return this.$lodash.truncate(params.name, { length: 9 })
            }
          }
        },
        // 数据组
        series: [
          {
            avoidLabelOverlap: false,
            label: { show: false },
            labelLine: { show: true },
            type: 'pie',
            radius: ['30%', '40%'],
            data: [],
            left: -150
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.orderTypeStatisticsChart, 'echarts_custom')
      this.chartOption.series[0].data = this.chartData
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true)
      // 添加表格自适应
      window.addEventListener('resize', () => {
        this.chart.resize()
      })
    },
    fetchData() {
      findTenantHomePageOrderStatistics().then(res => {
        if (res.code === 0) {
          this.chartData = [
            { value: res.data.total_number_of_funeral_service_orders, name: '殡仪服务类订单', itemStyle: { color: '#3aa1ff' }},
            { value: res.data.total_number_of_product, name: '附加产类订单', itemStyle: { color: '#36cbcb' }},
            { value: res.data.total_number_of_add_item_order, name: '新增项类订单', itemStyle: { color: '#cccccc' }}
          ]
          this.drawChart()
        }
      })
    }
  },
  beforeDestroy() {
    // 移除chart表格自适应监听
    window.removeEventListener('resize', () => {
      this.chart.resize()
    })
  }
}
</script>
<style lang="less" scoped>
.title {
  font-size: 14px;

  .icon {
    margin: 3px 3px 0 0;
    width: 3px;
    height: 15px;
    background-color: @primary-color;
  }
}
</style>

